import request from '@/utils/request'


// 查询作品-评价回复列表
export function listUserComment(query) {
  return request({
    url: '/platform/user-comment/list',
    method: 'get',
    params: query
  })
}

// 查询作品-评价回复分页
export function pageUserComment(query) {
  return request({
    url: '/platform/user-comment/page',
    method: 'get',
    params: query
  })
}

// 查询作品-评价回复详细
export function getUserComment(data) {
  return request({
    url: '/platform/user-comment/detail',
    method: 'get',
    params: data
  })
}

// 新增作品-评价回复
export function addUserComment(data) {
  return request({
    url: '/platform/user-comment/add',
    method: 'post',
    data: data
  })
}

// 修改作品-评价回复
export function updateUserComment(data) {
  return request({
    url: '/platform/user-comment/edit',
    method: 'post',
    data: data
  })
}

// 删除作品-评价回复
export function delUserComment(data) {
  return request({
    url: '/platform/user-comment/delete',
    method: 'post',
    data: data
  })
}
export function changeStatus(data) {
  return request({
    url: '/platform/user-comment/changeStatus',
    method: 'post',
    data: data
  })
}

export function changeStatus1(id,remark,status) {
  return request({
    url: '/platform/user-comment/changeStatus',
    method: 'post',
    data: {id:id,remark:remark,noticeStatus:status},
  })
}
